<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        style="width:150px"
        :src="appLogoImage"
        alt="logo"
      />
      <!-- <h2 class="brand-text text-primary ml-1">
                منصة مقييم
      </h2> -->
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          أنك غير مخول! 🔐
        </h2>
        <p class="mb-2">
          ليس لديك إذن للوصول إلى هذه الصفحة.!!
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="loginRoute()"
        >
          العودة إلى صفحة الرئيسية
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue';
import store from '@/store/index';
import { getHomeRouteForLoggedInUser } from '@/auth/utils';
import { $themeConfig } from '@themeConfig';
// App Name
const { appName, appLogoImage } = $themeConfig.app;

export default {
  components: {
    BLink, BImg, BButton,
  },
  data() {
    return {
      appName,
      appLogoImage,
      downImg: require('@/assets/images/pages/not-authorized.jpg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized.jpg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'));
      return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
